import tw from 'twin.macro'

import Button from 'components/Button'

export const Wrapper = tw.div`
    flex
    justify-between
    items-center
`

export const Toolbar = tw.div`flex gap-2 items-center`

export const CurrencyPairToolbar = tw.div`flex gap-[4px] items-center`

export const CurrencyPairDivider = tw.div`
    w-[1px]
    h-[19px]
    bg-gray-800
    transform[rotate(11.89deg)]
`

export const CurrencySelectors = tw.span`
    text-brand-light
    font-bahnschrift
    font-normal
    text-[12px]
    leading-[14px]
    text-center
    flex
    items-center
    gap-[4px]
    cursor-pointer
`

export const CurrencyButton = tw(Button)`
    p-1
`
