import { Ladder, Rung } from 'types/ladder'

// insert here global utils functions

// TBD: take the next number in sequence from a counter living with the socket
export const createReqId = () => Math.floor(Math.random() * Math.pow(10, 4))

export const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d)\.)/g, ',')
}

export const range = (n: number): number[] =>
  new Array(n).fill(undefined).map((_, idx) => idx)

export const splitPrice = (price: string) => {
  const [integer, decimal] = price.split('.')
  const integerWithComma = numberWithCommas(integer)

  return [
    integerWithComma.length > 0 ? integerWithComma : '0',
    decimal && decimal.length > 0 ? decimal : '00',
  ]
}

export const calculateSpread = (ask: number, bid: number) =>
  ((ask - bid) / ((ask + bid) / 2)) * 10000

export const clampSizeBuckets = (
  sizeBuckets: string[],
  normalSize: string,
): string[] => {
  if (sizeBuckets.length < 2) return sizeBuckets

  const newSizeBuckets = [...sizeBuckets]

  let maxBucketSize = newSizeBuckets[0]

  for (let i = 0; i < newSizeBuckets.length; i++) {
    if (newSizeBuckets[i].trim().length === 0) {
      newSizeBuckets[i] = normalSize
    }

    if (Number(newSizeBuckets[i]) < Number(maxBucketSize)) {
      newSizeBuckets[i] = maxBucketSize
    } else {
      maxBucketSize = newSizeBuckets[i]
    }
  }

  return newSizeBuckets
}

export const numericStringHasValue = (s: string) => s && s.trim().length > 0

export const formatNumber = (n: string) => {
  const number = Number(n)
  if (isNaN(number)) return n
  if (number <= 0) return n
  return numberWithCommas(number.toFixed(2))
}

export const makeMonthAgo = (): Date => {
  const date = new Date()
  date.setMonth(date.getMonth() - 1)
  return date
}

export const maybeNumber = (talosDecimal: string): number | null => {
  const decimalAsNumber = Number(talosDecimal)

  return isNaN(decimalAsNumber) ? null : decimalAsNumber
}

export const isNil = (x: unknown) => x == null

export const findRung = (amount: string, ladder: Ladder): Rung => {
  // The result will be undefined unless the `ladder` is sorted by ascending `Size`.

  const numAmount = maybeNumber(amount)

  if (ladder.length < 1 || isNil(numAmount)) {
    return null
  }

  let result = ladder[0]

  for (let i = 1; i < ladder.length; i++) {
    const numRungSize = maybeNumber(ladder[i]?.Size)

    if (isNil(numRungSize)) {
      return null
    }

    if (numRungSize <= numAmount) {
      result = ladder[i] // at least this rung
    } else {
      return result // past rung, return previous
    }
  }

  return result // selected last rung
}

export const unique = (list: string[]): string[] =>
  Array.from(new Set(list).keys())

export const indexBy = <Type>(
  entry: string,
  list: Type[],
): Record<string, Type> =>
  list?.reduce?.((res, rec) => ({ ...res, [rec[entry]]: rec }), {})

export const uniqueBy = <Type>(entry: string, list: Type[]): Type[] =>
  Object.values(indexBy<Type>(entry, list))

export const eqSet = <Type>(xs: Set<Type>, ys: Set<Type>): boolean =>
  xs.size === ys.size && [...xs].every((x) => ys.has(x))
