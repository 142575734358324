import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'
import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'

const identify: Record<string, (ob: unknown) => string> = {}

identify.currency = (currency: Currency): string => `${currency?.Symbol}`

identify.currencyPair = (currencyPair: CurrencyPair): string =>
  currencyPair
    ? `${identify.currency(currencyPair[0])}-${identify.currency(
        currencyPair[1],
      )}`
    : null

identify.streamingTileLocalSliver = (
  streamingTileLocalSliver: StreamingTileLocalSliver,
): string =>
  JSON.stringify([
    identify.currencyPair(streamingTileLocalSliver.currencyPair),
    streamingTileLocalSliver.sizeBuckets,
  ])

export default identify
